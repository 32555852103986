import SidebarMapController from './SidebarMapController.js';
import { MapUtils } from './MapUtils.js';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';

export default class PilotMapController extends SidebarMapController {
    constructor(containerId, $wire, componentEl) {
        super(containerId, $wire, componentEl);
        this.spinner = document.getElementById('mapSpinner');

        this.updating = false;
        this.dataReady = false; // Data is not yet ready
        this.totalPages = 0;
        this.setupEventListeners();
    }

    /**
     * Fetch all airports.
     */
    async getData() {
        console.log('called')
        if(this.updating === false) {
            console.log('updating')
            this.updating = true;

            for (let i = 1; i <= this.totalPages; i++) {
                console.log(i)
                this.removeExistingLayersAndSources(['flightsLayer-'+i, 'flightsSource-'+i, 'departureAirport-'+i, 'arrivalAirport-'+i, 'departureAirportSource-'+i, 'arrivalAirportSource-'+i]);
            }

            if (this.$wire) {
                try {
                    let currentPage = 1;
                    let lastPage;

                    do {
                        const data = await this.$wire.getData(currentPage);
                        console.log(currentPage)
                        console.log(data)
                        // Move to the right place on earth
                        if(currentPage === 1) {
                            if(data.data[0]) {
                                const bounds = new mapboxgl.LngLatBounds();

                                bounds.extend([data.data[0].departureAirportLon, data.data[0].departureAirportLat]);

                                this.map.flyTo({ center: bounds.getCenter(), zoom: 3 });
                            }
                        }

                        this.addInitialMarkers(currentPage, data.data);
                        //
                        this.drawFlightLines(currentPage, data.data);

                        // Push data of the current page to mapData
                        // this.mapData = this.mapData.concat(data.data);

                        // Update pages information
                        currentPage = data.current_page + 1;
                        lastPage = data.last_page;
                        this.totalPages = lastPage;

                    } while (currentPage <= lastPage);
                    this.dataReady = true; // Data is now ready
                    this.hideSpinner();
                } catch (error) {
                    console.error('Error fetching airports:', error);
                    throw error;
                }
            } else {
                throw new Error('$wire is not available.');
            }
            this.updating = false;
        }
    }

    /**
     * Add initial markers to the map.
     */
    addInitialMarkers(currentPage, data) {
        if (!data) {
            console.error('No data available in markersData.');
            return;
        }

        // Remove existing layers and sources
        //this.removeExistingLayersAndSources(['departureAirport', 'arrivalAirport', 'flightsLayer', 'flightsSource']);
        const depFeatures = data.map(airport =>
            MapUtils.createFeaturePilotMap(airport, 'departureAirport-'+currentPage, this.getIconType(airport, []), false)
        );

        const arrFeatures = data.map(airport =>
            MapUtils.createFeaturePilotMap(airport, 'arrivalAirport-'+currentPage, this.getIconType(airport, []), true)
        );

        const depGeoJSON = {
            type: 'FeatureCollection',
            features: depFeatures
        };

        const arrGeoJSON = {
            type: 'FeatureCollection',
            features: arrFeatures
        };

        this.addLayer('departureAirport-'+currentPage, depGeoJSON, 'departureAirportSource-'+currentPage);
        this.addLayer('arrivalAirport-'+currentPage, arrGeoJSON, 'arrivalAirportSource-'+currentPage);
    }

    /**
     * On map ready, fetch data and add markers.
     */
    async onMapReady() {
        try {
            this.showSpinner();
            // 1. Fetch airports data
            await this.getData();
        } catch (error) {
            console.error('Error during map data loading:', error);
            this.hideSpinner(); // Ensure spinner is hidden even on error
        }
    }

    /**
     * On map style updated, re-add markers and route lines.
     */
    async onMapStyleUpdated() {
        if (!this.dataReady) {
            console.warn('Data not ready, skipping onMapStyleUpdated.');
            return;
        }
        this.showSpinner();
        // 1. Fetch airports data
        await this.getData();
    }

    /**
     * Draw the route line on the map.
     */
    drawFlightLines(currentPage, data) {
        const lineFeatures = [];

        data.map(airport => {
            if ("posreps" in airport) {
                for (let i = 0; i < airport.posreps.length - 1; i++) {
                    const start = [airport.posreps[i].longitude, airport.posreps[i].latitude];
                    const end = [airport.posreps[i + 1].longitude, airport.posreps[i + 1].latitude];

                    // Generate great circle arc between start and end
                    const line = turf.greatCircle(start, end, { npoints: 1 });

                    // Handle LineString and MultiLineString geometries
                    if (line.geometry.type === 'LineString') {
                        // For LineString, unwrap coordinates
                        const adjustedCoordinates = MapUtils.unwrapCoordinates(line.geometry.coordinates);

                        lineFeatures.push({
                            type: 'Feature',
                            geometry: {
                                type: 'LineString',
                                coordinates: adjustedCoordinates
                            }
                        });
                    } else if (line.geometry.type === 'MultiLineString') {
                        // For MultiLineString, handle each LineString separately
                        line.geometry.coordinates.forEach((coords) => {
                            const adjustedCoordinates = MapUtils.unwrapCoordinates(coords);

                            lineFeatures.push({
                                type: 'Feature',
                                geometry: {
                                    type: 'LineString',
                                    coordinates: adjustedCoordinates
                                }
                            });
                        });
                    }
                }
            } else {
                const start = [airport.arrivalAirportLon, airport.arrivalAirportLat];
                const end = [airport.departureAirportLon, airport.departureAirportLat];

                // Generate great circle arc between start and end
                const line = turf.greatCircle(start, end, { npoints: 100 });

                // Handle LineString and MultiLineString geometries
                if (line.geometry.type === 'LineString') {
                    // For LineString, unwrap coordinates
                    const adjustedCoordinates = MapUtils.unwrapCoordinates(line.geometry.coordinates);

                    lineFeatures.push({
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: adjustedCoordinates
                        }
                    });
                } else if (line.geometry.type === 'MultiLineString') {
                    // For MultiLineString, handle each LineString separately
                    line.geometry.coordinates.forEach((coords) => {
                        const adjustedCoordinates = MapUtils.unwrapCoordinates(coords);

                        lineFeatures.push({
                            type: 'Feature',
                            geometry: {
                                type: 'LineString',
                                coordinates: adjustedCoordinates
                            }
                        });
                    });
                }
            }
        });

        const routeGeoJSON = {
            type: 'FeatureCollection',
            features: lineFeatures
        };

        this.map.addSource('flightsSource-' + currentPage, {
            type: 'geojson',
            data: routeGeoJSON
        });

        this.map.addLayer({
            id: 'flightsLayer-' + currentPage,
            type: 'line',
            source: 'flightsSource-' + currentPage,
            layout: {},
            paint: {
                'line-color': 'rgba(128, 128, 128, 0.5)', // Faint gray color
                'line-width': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    5, 1,    // At zoom level 5, line width is 1
                    10, 3,   // At zoom level 10, line width is 3
                    15, 5    // At zoom level 15 and above, line width is 5
                ]
            }
        });
    }

    calculateBearing(start, end) {
        const startPoint = turf.point(start);
        const endPoint = turf.point(end);
        return turf.bearing(startPoint, endPoint);
    }

    bearingDifference(bearing1, bearing2) {
        let diff = Math.abs(bearing1 - bearing2);
        if (diff > 180) {
            diff = 360 - diff;
        }
        return diff;
    }

    bearingSimplify(coordinates, bearingThreshold) {
        if (coordinates.length <= 2) {
            return coordinates.slice(); // Return a copy of the array
        }

        const simplifiedCoords = [coordinates[0]];
        let prevBearing = this.calculateBearing(coordinates[0], coordinates[1]);

        for (let i = 1; i < coordinates.length - 1; i++) {
            const currentBearing = this.calculateBearing(coordinates[i], coordinates[i + 1]);
            const bearingChange = this.bearingDifference(prevBearing, currentBearing);

            if (bearingChange >= bearingThreshold) {
                simplifiedCoords.push(coordinates[i]);
                prevBearing = currentBearing;
            }
        }

        simplifiedCoords.push(coordinates[coordinates.length - 1]);
        return simplifiedCoords;
    }

    /**
     * Get icon type based on airport properties.
     */
    getIconType(airport, favoriteAirports) {
        return airport.arrivalAirportBase || airport.departureAirportBase ? 'marker_base' : 'marker_airport';
    }

    setupEventListeners() {
        console.log('setting up event listeners')
        this.componentEl.addEventListener('update-map', async () => {
            try {
                this.showSpinner()
                // 1. Fetch airports data
                await this.getData();
            } catch (error) {
                console.error('Error during map data loading:', error);
                this.hideSpinner(); // Ensure spinner is hidden even on error
            }
        });
    }

    showSpinner() {
        console.log(this.spinner)
        console.log('show spinner')
        if(this.spinner){
            this.spinner.style.display = '';
        }

    }

    /**
     * Hide the loading spinner.
     */
    hideSpinner() {
        console.log('hide spinner')
        if(this.spinner){
            this.spinner.style.display = 'none';
        }
    }
}
