// MapUtils.js

import * as turf from '@turf/turf';

export const MapUtils = {
    /**
     * Unwrap coordinates to handle antimeridian crossing.
     */
    unwrapCoordinates(coordinates) {
        if (!coordinates || coordinates.length === 0) {
            console.error('Coordinates are undefined or empty.');
            return [];
        }

        let adjustedCoords = [coordinates[0]];
        let previousLon = coordinates[0][0];

        for (let i = 1; i < coordinates.length; i++) {
            let lon = coordinates[i][0];
            let lat = coordinates[i][1];
            let prevLon = previousLon;

            // Calculate the difference in longitude
            let delta = lon - prevLon;

            // Adjust lon to be continuous
            if (delta > 180) {
                while (lon - prevLon > 180) {
                    lon -= 360;
                }
            } else if (delta < -180) {
                while (lon - prevLon < -180) {
                    lon += 360;
                }
            }

            adjustedCoords.push([lon, lat]);
            previousLon = lon;
        }

        return adjustedCoords;
    },

    /**
     * Create a GeoJSON feature for an airport.
     */
    createFeature(airport, type, iconType, clickable = true) {
        return {
            type: 'Feature',
            properties: {
                id: airport.id,
                name: airport.name,
                identifiers: airport.identifiers,
                countryCode: airport.countryCode || 'us',
                type: type,
                iconType: iconType,
                clickable: clickable
            },
            geometry: {
                type: 'Point',
                coordinates: [airport.lon, airport.lat]
            }
        };
    },

    createFeaturePilotMap(airport, type, iconType, arrival = false) {
        if(arrival){
            return {
                type: 'Feature',
                properties: {
                    id: airport.arrivalWorldAirportId,
                    name: airport.arrivalAirportName,
                    identifiers: airport.arrivalAirportIcao + ' / ' + airport.arrivalAirportIata,
                    type: type,
                    iconType: iconType,
                    clickable: false
                },
                geometry: {
                    type: 'Point',
                    coordinates: [airport.arrivalAirportLon, airport.arrivalAirportLat]
                }
            };
        } else {
            return {
                type: 'Feature',
                properties: {
                    id: airport.departureWorldAirportId,
                    name: airport.departureAirportName,
                    identifiers: airport.departureAirportIcao + ' / ' + airport.departureAirportIata,
                    type: type,
                    iconType: iconType,
                    clickable: false
                },
                geometry: {
                    type: 'Point',
                    coordinates: [airport.departureAirportLon, airport.departureAirportLat]
                }
            };
        }

    },

    /**
     * Generate HTML for airport information in the sidebar.
     */
    generateAirportInfoHTML(airport) {
        return `
            <div class="flex mb-2 items-start justify-between dark:text-white">
                <div>
                    ${airport.name}
                </div>
                <img class="flex-none" src="https://flagcdn.com/32x24/${airport.countryCode.toLowerCase()}.png"
                      srcset="https://flagcdn.com/64x48/${airport.countryCode.toLowerCase()}.png 2x, https://flagcdn.com/96x72/${airport.countryCode.toLowerCase()}.png 3x"
                      width="20"
                      height="15"/>
            </div>
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
                ${airport.identifiers}
            </div>
        `;
    }
};
