// SidebarMapController.js

import  BaseMapController from './BaseMapController.js';
import { MapUtils } from './MapUtils.js';
import * as turf from '@turf/turf';

export default class SidebarMapController extends BaseMapController {
    constructor(containerId, $wire, componentEl) {
        super(containerId, $wire, componentEl);
        this.markersData = {};
        this.selectedDestination = null;
        this.currentRouteData = null;
        this.requestId = 0;

        // Sidebar elements
        this.sidebarElements = this.getSidebarElements();

        this.setupEventListeners();
        this.setupButtonEventHandlers();
    }

    /**
     * Get references to sidebar elements.
     * To be implemented by subclasses if needed.
     */
    getSidebarElements() {
        // Placeholder for subclasses to implement
        return {};
    }

    /**
     * Set up event listeners.
     * To be implemented by subclasses if needed.
     */
    setupEventListeners() {
        // Placeholder for subclasses to implement
    }

    /**
     * Setup event handlers for buttons.
     * To be implemented by subclasses if needed.
     */
    setupButtonEventHandlers() {
        // Placeholder for subclasses to implement
    }

    /**
     * Handle feature click events.
     * To be implemented by subclasses.
     */
    handleFeatureClick(feature) {
        // Placeholder for subclasses to implement
    }

    /**
     * Get the icon type for a marker.
     */
    getIconType(airport, favoriteAirports = [], isCurrentLocation = false) {
        const isFavorite = favoriteAirports.some(fav => fav.id === airport.id);

        if (isCurrentLocation) {
            return isFavorite ? 'marker_favorite_base' : 'marker_location';
        }

        if (isFavorite) {
            return airport.base ? 'marker_favorite_base' : 'marker_favorite_airport';
        } else {
            return airport.base ? 'marker_base' : 'marker_airport';
        }
    }

    /**
     * Helper method to show or hide a button.
     */
    toggleButtonVisibility(buttonElement, shouldShow) {
        if (buttonElement) {
            buttonElement.style.display = shouldShow ? 'block' : 'none';
        }
    }

    /**
     * Draw a route line on the map.
     */
    drawRouteLine(routingData) {
        if (!routingData || routingData.length < 2) {
            console.warn('Insufficient routing data to draw a route.');
            return;
        }

        // Remove existing route layer and source if they exist
        this.removeRouteLine();

        // Initialize an array to hold all line features
        let lineFeatures = [];

        // Iterate over each pair of consecutive points
        for (let i = 0; i < routingData.length - 1; i++) {
            const start = [routingData[i].longitude, routingData[i].latitude];
            const end = [routingData[i + 1].longitude, routingData[i + 1].latitude];

            // Generate great circle arc between start and end
            const line = turf.greatCircle(start, end, { npoints: 100 });

            // Handle LineString and MultiLineString geometries
            if (line.geometry.type === 'LineString') {
                // For LineString, unwrap coordinates
                const adjustedCoordinates = MapUtils.unwrapCoordinates(line.geometry.coordinates);

                lineFeatures.push({
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: adjustedCoordinates
                    }
                });
            } else if (line.geometry.type === 'MultiLineString') {
                // For MultiLineString, handle each LineString separately
                line.geometry.coordinates.forEach((coords) => {
                    const adjustedCoordinates = MapUtils.unwrapCoordinates(coords);

                    lineFeatures.push({
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: adjustedCoordinates
                        }
                    });
                });
            }
        }

        // Create a GeoJSON LineString with all the coordinates
        const routeGeoJSON = {
            type: 'FeatureCollection',
            features: lineFeatures
        };

        this.map.addSource('routeSource', {
            type: 'geojson',
            data: routeGeoJSON
        });

        const theme = this.getTheme();
        const lineColor = theme === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)';

        this.map.addLayer({
            id: 'routeLayer',
            type: 'line',
            source: 'routeSource',
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-color': lineColor,
                'line-width': 3
            }
        });
    }

    /**
     * Remove the route line from the map.
     */
    removeRouteLine() {
        if (this.map.getLayer('routeLayer')) {
            this.map.removeLayer('routeLayer');
        }
        if (this.map.getSource('routeSource')) {
            this.map.removeSource('routeSource');
        }
    }
}
