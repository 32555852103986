// BaseMapController.js

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'my-tiles';

export default class BaseMapController {
    constructor(containerId, $wire, componentEl) {
        this.containerId = containerId;
        this.$wire = $wire;
        this.componentEl = componentEl;
        this.map = null;
        this.icons = {};
        this.mapReady = false;
        this.interactivitySetup = {};
        this.popup = null;

        this.initialize();
    }

    /**
     * Initialize the map controller.
     */
    initialize() {
        this.initializeMap();
        this.addThemeChangeListener();
    }

    /**
     * Initialize the Mapbox map.
     */
    initializeMap() {
        console.log('start-initializeMap-BaseMapController')
        const theme = this.getTheme();
        const styleUrl = this.getMapStyleUrl(theme);

        this.showSpinner();
        this.mapReady = false;

        this.map = new mapboxgl.Map({
            container: this.containerId,
            style: styleUrl,
            center: [0, 0],
            zoom: 2,
            projection: 'globe',
            attributionControl: false,
            logoPosition: 'top-right'
        });

        this.map.on('load', async () => {
            this.applyMapTheme(theme);

            try {
                await this.loadCustomIcons();
                this.mapReady = true;
                this.onMapReady();
                // Removed hideSpinner() from here
            } catch (error) {
                console.error('Error during map initialization:', error);
                this.hideSpinner(); // Only hide spinner on error
            }
        });
    }

    /**
     * Placeholder method to be implemented by subclasses when the map is ready.
     */
    onMapReady() {
        // To be implemented by subclasses
    }

    /**
     * Get the current theme.
     */
    getTheme() {
        return document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    }

    /**
     * Get the Mapbox style URL based on the theme.
     */
    getMapStyleUrl(theme) {
        return theme === 'dark'
            ? '/assets/maps/vamsys-dark.json'
            : '/assets/maps/vamsys-light.json';
    }

    /**
     * Apply fog settings based on the theme.
     */
    applyMapTheme(theme) {
        const fogSettings = theme === 'dark' ? {
            color: 'rgb(0, 0, 0)',
            'high-color': 'rgb(70, 70, 70)',
            'horizon-blend': 0.5,
            'space-color': 'rgb(11, 11, 25)',
            'star-intensity': 0.1
        } : {
            color: 'rgb(255, 255, 255)',
            'high-color': 'rgb(200, 200, 200)',
            'horizon-blend': 0.5,
            'space-color': 'rgb(240, 240, 255)',
            'star-intensity': 0.0
        };
        this.map.setFog(fogSettings);
    }

    /**
     * Add a listener for theme changes.
     */
    addThemeChangeListener() {
        window.addEventListener('theme-changed', () => {
            this.updateMapStyle();
        });
    }

    /**
     * Update the map style when the theme changes.
     */
    updateMapStyle() {
        const theme = this.getTheme();
        const styleUrl = this.getMapStyleUrl(theme);

        this.showSpinner();
        this.mapReady = false;
        this.map.setStyle(styleUrl);

        const onStyleData = async (e) => {
            if (e.dataType === 'style') {
                this.map.off('styledata', onStyleData);
                this.applyMapTheme(theme);

                try {
                    await this.loadCustomIcons();
                    this.mapReady = true;
                    this.onMapStyleUpdated();
                    this.hideSpinner(); // Hide spinner after style update
                } catch (error) {
                    console.error('Error during map style update:', error);
                    this.hideSpinner();
                }
            }
        };

        this.map.on('styledata', onStyleData);
    }

    /**
     * Placeholder method to be implemented by subclasses when the map style is updated.
     */
    onMapStyleUpdated() {
        // To be implemented by subclasses
    }

    /**
     * Load custom icons into the map.
     */
    async loadCustomIcons() {
        const icons = {
            'marker_location': {
                url: '/assets/images/map/2024/small/marker_location.png',
                width: 20,
                height: 20
            },
            'marker_favorite_base': {
                url: '/assets/images/map/2024/small/marker_favorite_base.png',
                width: 20,
                height: 20
            },
            'marker_favorite_airport': {
                url: '/assets/images/map/2024/small/marker_favorite_airport.png',
                width: 20,
                height: 20
            },
            'marker_base': {
                url: '/assets/images/map/2024/small/marker_base.png',
                width: 16,
                height: 16
            },
            'marker_airport': {
                url: '/assets/images/map/2024/small/marker_airport.png',
                width: 16,
                height: 16
            },
            'marker_jumpseat_favorite': {
                url: '/assets/images/map/2024/small/marker_jumpseat_favorite.png',
                width: 8,
                height: 8
            },
            'marker_jumpseat': {
                url: '/assets/images/map/2024/small/marker_jumpseat.png',
                width: 5,
                height: 5
            }
        };

        this.icons = icons;

        const promises = [];

        for (const [key, icon] of Object.entries(icons)) {
            const promise = new Promise((resolve, reject) => {
                if (this.map.hasImage(key)) {
                    resolve();
                } else {
                    this.map.loadImage(icon.url, (error, image) => {
                        if (error) {
                            console.error(`Error loading icon ${key}:`, error);
                            reject(error);
                        } else {
                            this.map.addImage(key, image);
                            resolve();
                        }
                    });
                }
            });
            promises.push(promise);
        }

        return Promise.all(promises);
    }

    /**
     * Add a layer to the map.
     */
    addLayer(layerId, geoJSON, sourceId) {
        this.map.addSource(sourceId, {
            type: 'geojson',
            data: geoJSON
        });

        // Add the layer without a beforeId to place it above all existing layers
        this.map.addLayer({
            id: layerId,
            type: 'symbol',
            source: sourceId,
            layout: {
                'icon-image': ['get', 'iconType'],
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-padding': 2,
                'symbol-z-order': 'viewport-y'
            }
        });

        // Setup interactivity
        this.setupSymbolLayerInteractivity(layerId);
    }

    /**
     * Setup interactivity for a layer.
     */
    setupSymbolLayerInteractivity(layerId) {
        if (this.interactivitySetup[layerId]) {
            return;
        }

        // Define event handlers
        const onMouseEnter = (e) => {
            this.map.getCanvas().style.cursor = 'pointer';

            const feature = e.features[0];
            const coordinates = feature.geometry.coordinates.slice();
            const { name, identifiers, iconType } = feature.properties;

            // Calculate popup offsets based on icon dimensions
            const icon = this.icons[iconType];
            const markerHeight = icon.height;
            const markerRadius = icon.width / 2;
            const linearOffset = icon.width / 2;
            const popupOffsets = {
                'top': [0, 0],
                'top-left': [0, 0],
                'top-right': [0, 0],
                'bottom': [0, -markerHeight],
                'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
                'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
                'left': [markerRadius, (markerHeight - markerRadius) * -1],
                'right': [-markerRadius, (markerHeight - markerRadius) * -1]
            };

            if (this.popup) {
                this.popup.remove();
            }

            this.popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                offset: popupOffsets,
                className: 'map-airport-popup'
            })
                .setLngLat(coordinates)
                .setHTML(`<strong>${name}</strong><br/>${identifiers}`)
                .addTo(this.map);
        };

        const onMouseLeave = () => {
            this.map.getCanvas().style.cursor = '';
            if (this.popup) {
                this.popup.remove();
                this.popup = null;
            }
        };

        const onClick = (e) => {
            const feature = e.features[0];
            this.handleFeatureClick(feature);
        };

        // Attach event handlers
        this.map.on('mouseenter', layerId, onMouseEnter);
        this.map.on('mouseleave', layerId, onMouseLeave);
        this.map.on('click', layerId, onClick);

        // Store event handlers for removal later
        this.interactivitySetup[layerId] = {
            onMouseEnter,
            onMouseLeave,
            onClick
        };
    }

    /**
     * Remove interactivity from a layer.
     */
    removeLayerInteractivity(layerId) {
        if (this.interactivitySetup[layerId]) {
            const handlers = this.interactivitySetup[layerId];
            this.map.off('mouseenter', layerId, handlers.onMouseEnter);
            this.map.off('mouseleave', layerId, handlers.onMouseLeave);
            this.map.off('click', layerId, handlers.onClick);
            delete this.interactivitySetup[layerId];
        }
    }

    /**
     * Remove existing layers and sources.
     */
    removeExistingLayersAndSources(layerIds) {
        layerIds.forEach((layerId) => {
            if (this.map.getLayer(layerId)) {
                this.map.removeLayer(layerId);
                // Clear interactivity setup flag
                if (this.interactivitySetup && this.interactivitySetup[layerId]) {
                    this.removeLayerInteractivity(layerId);
                }
            }
            if (this.map.getSource(layerId)) {
                this.map.removeSource(layerId);
            }
        });
    }

    /**
     * Show the loading spinner.
     */
    showSpinner() {
        const spinner = document.getElementById('mapSpinner');
        if (spinner) {
            spinner.style.display = '';
        }
    }

    /**
     * Hide the loading spinner.
     */
    hideSpinner() {
        const spinner = document.getElementById('mapSpinner');
        if (spinner) {
            spinner.style.display = 'none';
        }
    }

    /**
     * Destroy the map instance.
     */
    destroyMap() {
        if (this.map) {
            // Remove all interactivity
            Object.keys(this.interactivitySetup).forEach(layerId => {
                this.removeLayerInteractivity(layerId);
            });
            this.map.remove();
            this.map = null;
        }
    }

    /**
     * Handle feature click events.
     * Placeholder to be implemented by subclasses.
     */
    handleFeatureClick(feature) {
        // To be implemented by subclasses
    }

    /**
     * Fit the map to the markers.
     */
    fitMapToMarkers(markers) {
        if (!this.map) return;

        const bounds = new mapboxgl.LngLatBounds();

        markers.forEach(marker => {
            if (marker && marker.lon && marker.lat) {
                bounds.extend([marker.lon, marker.lat]);
            }
        });

        if (!bounds.isEmpty()) {
            this.map.flyTo({ center: bounds.getCenter(), zoom: 3 });
            // Alternatively, use fitBounds for more precise control:
            // this.map.fitBounds(bounds, {
            //     padding: 50,
            //     maxZoom: 15,
            //     duration: 1000
            // });
        }
    }
}
