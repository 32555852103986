// DestinationMapController.js

import SidebarMapController from './SidebarMapController.js';
import { MapUtils } from './MapUtils.js';
import * as turf from '@turf/turf';

export default class DestinationMapController extends SidebarMapController {
    constructor(containerId, $wire, componentEl) {
        super(containerId, $wire, componentEl);
        this.selectedAirport = null; // The initial selected airport
        this.selectedDestinationAirport = null; // The selected destination airport
        this.availableAirports = {}; // Airports available from the selected airport
        this.jumpseatEnabled = false; // Indicates if jumpseat is enabled
        this.dataReady = false; // Data is not yet ready

        // Sidebar elements
        this.sidebarElements = this.getSidebarElements();

        this.setupEventListeners();
        this.setupButtonEventHandlers();
    }

    /**
     * On map ready, fetch data and add markers.
     */
    async onMapReady() {
        try {
            // 1. Fetch airports data
            await this.getAirports();

            // 2. Fit the map to initial markers (initiates flyTo)
            if (this.markersData.currentAirport) {
                const { lon, lat } = this.markersData.currentAirport;
                if (lon && lat) {
                    this.map.flyTo({ center: [lon, lat], zoom: 3 });
                }
            } else {
                // If currentAirport is not available, fit to all markers
                this.fitMapToMarkers(Object.values(this.markersData.airports));
            }

            // 3. Hide the spinner immediately after flyTo starts
            this.hideSpinner();

            // 4. Add initial markers to the map
            this.addInitialMarkers();

            // 5. Update the sidebar
            this.updateSidebarOnLoad();
        } catch (error) {
            console.error('Error during map data loading:', error);
            this.hideSpinner(); // Ensure spinner is hidden even on error
        }
    }

    /**
     * On map style updated, re-add markers and route lines.
     */
    onMapStyleUpdated() {
        if (!this.dataReady) {
            console.warn('Data not ready, skipping onMapStyleUpdated.');
            return;
        }

        if (this.selectedAirport) {
            // Show available airports
            this.showAvailableAirports();
            this.drawRoutesToAvailableAirports();
        } else {
            // Add initial markers
            this.addInitialMarkers();
            this.hideSpinner();
        }

        // Redraw route line if it exists
        if (this.currentRouteData) {
            this.drawRouteLine(this.currentRouteData);
        }
    }

    /**
     * Get references to sidebar elements.
     */
    getSidebarElements() {
        return {
            sidebar: document.getElementById('sidebar'),
            loadingIndicator: document.getElementById('sidebar-loadingIndicator'),
            content: document.getElementById('sidebar-content'),
            routeDataContainer: document.getElementById('sidebar-route-data'),
            routeData: {
                aircraftTypes: document.getElementById('data-aircraftTypes'),
                operators: document.getElementById('data-operators'),
                ete: document.getElementById('data-ETE'),
                distance: document.getElementById('data-distance')
            },
            departure: document.getElementById('data-departureAirport'),
            arrival: document.getElementById('data-arrivalAirport'),
            buttons: {
                resetMap: document.getElementById('reset-map-button'),
                originAirport: document.getElementById('origin-airport-button'),
                jumpseatOrigin: document.getElementById('jumpseat-origin-button'),
                destinationAirport: document.getElementById('destination-airport-button'),
                jumpseatDestination: document.getElementById('jumpseat-destination-button')
            }
        };
    }

    /**
     * Set up event listeners.
     */
    setupEventListeners() {
        // Livewire data updates (if any)
        this.componentEl.addEventListener('mapDataUpdated', (event) => {
            this.handleMapDataUpdated(event.detail);
        });

        // Close Sidebar Button
        const closeButton = document.getElementById('sidebar-close');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                this.resetMapToInitialState();
            });
        }

        this.componentEl.addEventListener('start-map-loading', () => {
            this.showSpinner();
            this.resetMapToInitialState();
            this.getAirports().then(() => {
                this.addInitialMarkers();
                this.hideSpinner();
            });
        });
    }

    /**
     * Setup event handlers for buttons.
     */
    setupButtonEventHandlers() {
        const { resetMap, originAirport, jumpseatOrigin, destinationAirport, jumpseatDestination } = this.sidebarElements.buttons;

        if (resetMap) {
            resetMap.addEventListener('click', () => {
                this.resetMapToInitialState();
            });
        }

        if (originAirport) {
            originAirport.addEventListener('click', () => {
                if (this.selectedAirport) {
                    window.open(`/phoenix/resources/airports/${this.selectedAirport.id}`, '_blank');
                }
            });
        }

        if (jumpseatOrigin) {
            jumpseatOrigin.addEventListener('click', () => {
                if (this.selectedAirport) {
                    this.doJumpseat(this.selectedAirport.id);
                }
            });
        }

        if (destinationAirport) {
            destinationAirport.addEventListener('click', () => {
                if (this.selectedDestinationAirport) {
                    window.open(`/phoenix/resources/airports/${this.selectedDestinationAirport.id}`, '_blank');
                }
            });
        }

        if (jumpseatDestination) {
            jumpseatDestination.addEventListener('click', () => {
                if (this.selectedDestinationAirport) {
                    this.doJumpseat(this.selectedDestinationAirport.id);
                }
            });
        }
    }

    /**
     * Fetch all airports.
     */
    async getAirports() {
        if (this.$wire) {
            try {
                const data = await this.$wire.getAirports();
                this.markersData = {
                    airports: data.airports,
                    currentAirport: data.currentAirport,
                    favoriteAirports: data.favoriteAirports || {}
                };
                this.jumpseatEnabled = data.jumpseatEnabled; // Store jumpseatEnabled
                this.dataReady = true; // Data is now ready
            } catch (error) {
                console.error('Error fetching airports:', error);
                throw error;
            }
        } else {
            throw new Error('$wire is not available.');
        }
    }

    /**
     * Add initial markers to the map.
     */
    addInitialMarkers() {
        if (!this.markersData.airports) {
            console.error('No airports data available in markersData.');
            return;
        }

        // Remove existing layers and sources
        this.removeExistingLayersAndSources(['initialMarkers', 'availableMarkers', 'selectedAirportMarker', 'availableRoutesLayer', 'availableRoutesSource']);

        const favoriteAirports = Object.values(this.markersData.favoriteAirports);

        const features = Object.values(this.markersData.airports).map(airport => MapUtils.createFeature(airport, 'initialAirport', this.getIconType(airport, favoriteAirports)));

        const geoJSON = {
            type: 'FeatureCollection',
            features: features
        };

        this.addLayer('initialMarkers', geoJSON, 'initialMarkers');
    }

    /**
     * Handle feature click events.
     */
    handleFeatureClick(feature) {
        const airportId = feature.properties.id;
        const featureType = feature.properties.type;

        // Increment the request ID
        const currentRequestId = ++this.requestId;

        if (featureType === 'initialAirport') {
            this.handleInitialAirportClick(airportId, currentRequestId);
        } else if (featureType === 'availableAirport') {
            this.handleAvailableAirportClick(airportId, currentRequestId);
        }
    }

    /**
     * Handle initial airport click.
     */
    handleInitialAirportClick(airportId, currentRequestId) {
        this.selectedAirport = this.markersData.airports[airportId];

        // Hide initial markers
        this.removeExistingLayersAndSources(['initialMarkers']);

        // Show loading indicator
        this.showLoadingIndicator();

        // Call Livewire method to get available airports
        if (this.$wire) {
            this.$wire.airportSelectedQuery(airportId).then((data) => {
                if (currentRequestId === this.requestId) {
                    this.availableAirports = data.availableAirports; // This is an object
                    this.showAvailableAirports();
                    this.drawRoutesToAvailableAirports();
                    this.showSidebar();
                }
            }).catch((error) => {
                if (currentRequestId === this.requestId) {
                    console.error('Error fetching available airports:', error);
                }
            }).finally(() => {
                if (currentRequestId === this.requestId) {
                    // Hide loading indicator
                    this.hideLoadingIndicator();
                }
            });
        }
    }

    /**
     * Show available airports on the map.
     */
    showAvailableAirports() {
        this.removeExistingLayersAndSources(['availableMarkers', 'selectedAirportMarker']);

        const favoriteAirports = Object.values(this.markersData.favoriteAirports);

        // Create features for available airports
        const availableFeatures = Object.values(this.availableAirports).map(airport => MapUtils.createFeature(airport, 'availableAirport', this.getIconType(airport, favoriteAirports)));

        const availableGeoJSON = {
            type: 'FeatureCollection',
            features: availableFeatures
        };

        this.addLayer('availableMarkers', availableGeoJSON, 'availableMarkers');

        // Create feature for the selected airport (non-clickable)
        const selectedFeature = MapUtils.createFeature(this.selectedAirport, 'selectedAirport', this.getIconType(this.selectedAirport, favoriteAirports), false);

        const selectedGeoJSON = {
            type: 'FeatureCollection',
            features: [selectedFeature]
        };

        // Add selected airport marker without interactivity
        this.map.addSource('selectedAirportMarker', {
            type: 'geojson',
            data: selectedGeoJSON
        });

        this.map.addLayer({
            id: 'selectedAirportMarker',
            type: 'symbol',
            source: 'selectedAirportMarker',
            layout: {
                'icon-image': ['get', 'iconType'],
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-padding': 2
            }
        });
    }

    /**
     * Draw routes from the selected airport to all available airports.
     */
    drawRoutesToAvailableAirports() {
        // Remove existing route layer and source if they exist
        this.removeExistingLayersAndSources(['availableRoutesLayer', 'availableRoutesSource']);

        const lineFeatures = [];

        Object.values(this.availableAirports).forEach((airport) => {
            const start = [this.selectedAirport.lon, this.selectedAirport.lat];
            const end = [airport.lon, airport.lat];

            const line = turf.greatCircle(start, end, { npoints: 100 });

            // Handle LineString and MultiLineString geometries
            if (line.geometry.type === 'LineString') {
                // For LineString, unwrap coordinates
                const adjustedCoordinates = MapUtils.unwrapCoordinates(line.geometry.coordinates);

                lineFeatures.push({
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: adjustedCoordinates
                    }
                });
            } else if (line.geometry.type === 'MultiLineString') {
                // For MultiLineString, handle each LineString separately
                line.geometry.coordinates.forEach((coords) => {
                    const adjustedCoordinates = MapUtils.unwrapCoordinates(coords);

                    lineFeatures.push({
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: adjustedCoordinates
                        }
                    });
                });
            }
        });

        const routeGeoJSON = {
            type: 'FeatureCollection',
            features: lineFeatures
        };

        this.map.addSource('availableRoutesSource', {
            type: 'geojson',
            data: routeGeoJSON
        });

        this.map.addLayer({
            id: 'availableRoutesLayer',
            type: 'line',
            source: 'availableRoutesSource',
            layout: {},
            paint: {
                'line-color': 'rgba(128, 128, 128, 0.5)', // Faint gray color
                'line-width': 1
            }
        });
    }

    /**
     * Handle available airport click.
     */
    handleAvailableAirportClick(airportId, currentRequestId) {
        const destinationAirport = this.availableAirports[airportId];
        this.selectedDestinationAirport = destinationAirport;

        // Remove the lines between the selected airport and all available airports
        this.removeExistingLayersAndSources(['availableRoutesLayer', 'availableRoutesSource']);

        // Show loading indicator
        this.showLoadingIndicator();

        // Call Livewire method to get route data
        if (this.$wire) {
            this.$wire.airportRouteQuery(this.selectedAirport.id, airportId).then((data) => {
                if (currentRequestId === this.requestId) {
                    if (data.routing && data.routing.length > 1) {
                        this.currentRouteData = data.routing;
                        this.drawRouteLine(this.currentRouteData);
                    } else {
                        console.error('Insufficient routing data to draw a route.');
                    }

                    this.updateSidebar(destinationAirport, data);
                }
            }).catch((error) => {
                if (currentRequestId === this.requestId) {
                    console.error('Error fetching route data:', error);
                }
            }).finally(() => {
                if (currentRequestId === this.requestId) {
                    // Hide loading indicator
                    this.hideLoadingIndicator();
                }
            });
        }
    }

    showLoadingIndicator() {
        const { loadingIndicator, content } = this.sidebarElements;
        if (loadingIndicator) {
            loadingIndicator.style.display = 'flex';
        }
        if (content) {
            content.style.display = 'none';
        }
    }

    hideLoadingIndicator() {
        const { loadingIndicator, content } = this.sidebarElements;
        if (loadingIndicator) {
            loadingIndicator.style.display = 'none';
        }
        if (content) {
            content.style.display = 'block';
        }
    }

    /**
     * Draw the route line on the map.
     */
    drawRouteLine(routingData) {
        // Call the base class method to draw the route line
        super.drawRouteLine(routingData);
    }

    /**
     * Show the sidebar.
     */
    showSidebar() {
        const { sidebar, loadingIndicator, content, routeDataContainer, buttons, departure } = this.sidebarElements;

        if (sidebar) {
            sidebar.classList.remove('hidden');
            loadingIndicator.style.display = 'none';
            content.style.display = 'block';

            // Hide route data on initial display
            routeDataContainer.style.display = 'none';

            // Update departure airport info
            if (departure) {
                departure.innerHTML = MapUtils.generateAirportInfoHTML(this.selectedAirport);
            }

            // Update origin airport button
            buttons.originAirport.innerHTML = this.generateAirportInfoButtonHTML(this.selectedAirport);

            // Show origin airport button
            this.toggleButtonVisibility(buttons.originAirport, true);

            // Show jumpseat to origin button if jumpseatEnabled
            if (this.jumpseatEnabled) {
                this.toggleButtonVisibility(buttons.jumpseatOrigin, true);
                buttons.jumpseatOrigin.textContent = `Jumpseat to ${this.selectedAirport.identifiers}`;
            } else {
                this.toggleButtonVisibility(buttons.jumpseatOrigin, false);
            }

            // Hide destination airport button and jumpseatDestination button until an available airport is clicked
            this.toggleButtonVisibility(buttons.destinationAirport, false);
            this.toggleButtonVisibility(buttons.jumpseatDestination, false);

            // Show reset map button
            this.toggleButtonVisibility(buttons.resetMap, true);
        }
    }

    /**
     * Update the sidebar with route data.
     */
    updateSidebar(destinationAirport, routeData) {
        const { routeDataContainer, routeData: routeDataElements, buttons, arrival } = this.sidebarElements;

        // Update arrival airport info
        if (arrival) {
            arrival.innerHTML = MapUtils.generateAirportInfoHTML(destinationAirport);
        }

        // Update destination airport button
        buttons.destinationAirport.innerHTML = this.generateAirportInfoButtonHTML(destinationAirport);

        // Show destination airport button
        this.toggleButtonVisibility(buttons.destinationAirport, true);

        // Show jumpseat to destination button if jumpseatEnabled
        if (this.jumpseatEnabled) {
            this.toggleButtonVisibility(buttons.jumpseatDestination, true);
            buttons.jumpseatDestination.textContent = `Jumpseat to ${destinationAirport.identifiers}`;
        }

        // Update route data
        routeDataContainer.style.display = 'grid';

        routeDataElements.aircraftTypes.innerText = routeData.aircraftTypes;
        routeDataElements.operators.innerText = routeData.operators;
        routeDataElements.ete.innerText = routeData.ete;
        routeDataElements.distance.innerText = routeData.distance;
    }

    /**
     * Reset the map to its initial state.
     */
    resetMapToInitialState() {
        this.selectedAirport = null;
        this.selectedDestinationAirport = null;
        this.availableAirports = {};
        this.currentRouteData = null;
        this.requestId = 0;

        // Remove existing layers and sources
        this.removeExistingLayersAndSources(['availableMarkers', 'availableRoutesLayer', 'availableRoutesSource', 'selectedAirportMarker']);

        // Remove existing route lines
        this.removeRouteLine();

        // Add initial markers
        this.addInitialMarkers();

        // Hide the sidebar
        const { sidebar, buttons, arrival, routeDataContainer } = this.sidebarElements;
        if (sidebar) {
            sidebar.classList.add('hidden');
        }

        // Clear arrival airport info
        if (arrival) {
            arrival.innerHTML = '';
        }

        // Hide route data
        if (routeDataContainer) {
            routeDataContainer.style.display = 'none';
        }

        // Hide buttons
        this.toggleButtonVisibility(buttons.resetMap, false);
        this.toggleButtonVisibility(buttons.originAirport, false);
        this.toggleButtonVisibility(buttons.jumpseatOrigin, false);
        this.toggleButtonVisibility(buttons.destinationAirport, false);
        this.toggleButtonVisibility(buttons.jumpseatDestination, false);
    }

    /**
     * Handle the jumpseat action.
     */
    doJumpseat(airportId) {
        if (!airportId) {
            console.error('No airport ID provided for jumpseat.');
            return;
        }

        // Call Livewire method
        if (this.$wire) {
            this.$wire.jumpseatToLocation(airportId).then(() => {
                // Since the response is a redirect, we don't need to do anything
            }).catch((error) => {
                console.error('Error during jumpseat:', error);
            });
        } else {
            console.error('$wire is not available.');
        }
    }

    /**
     * Get icon type based on airport properties.
     */
    getIconType(airport, favoriteAirports) {
        const isFavorite = favoriteAirports.some(fav => fav.id === airport.id);

        if (isFavorite) {
            return airport.base ? 'marker_favorite_base' : 'marker_favorite_airport';
        } else {
            return airport.base ? 'marker_base' : 'marker_airport';
        }
    }

    /**
     * Generate HTML for the Airport Information button.
     */
    generateAirportInfoButtonHTML(airport) {
        return `
            ${airport.identifiers} Information
        `;
    }

    /**
     * Handle map data updates from Livewire (if applicable).
     */
    handleMapDataUpdated(data) {
        // If your Livewire component sends data updates, handle them here
        // For example, update markersData and re-add markers
    }
}
